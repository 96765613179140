import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_gori/helpers'

export function CalculateLabels() {
  return (
    <section className='calculate-labels overflow-x-hidden bg-white'>
      <div className='container'>
        <h2 className='text-primary h2 text-left mb-12'>Calculate Your Labels</h2>
        <div className='d-flex flex-column flex-md-row justify-content-center align-items-center w-100 gap-19'>
          <img
            className='mw-100 mw-md-50'
            src={toAbsoluteUrl('/media/gori/landing/calculate-labels-graphic.webp')}
            alt='calculate-labels-graphic'
          />
          <div>
            <p className='mb-9'>
              Input your shipment information, and ShipBae will provide all available rates for the
              shipment.
              <br />
              Log in to ShipBae to see available services and rates for your shipments!
            </p>
            <Link
              to='/auth'
              className='btn btn-outline btn-primary hover-orange rounded-pill text-center d-block w-fit-content'
            >
              <img
                className='me-3'
                src={toAbsoluteUrl('/media/gori/landing/store.svg')}
                alt='store-icon'
              />
              Log Into ShipBae
            </Link>
          </div>
        </div>
      </div>
    </section>
  )
}
